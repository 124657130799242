import Swiper, {Autoplay, EffectCoverflow, EffectFade, Navigation, Pagination} from 'swiper';
import GLightbox from "glightbox";

document.addEventListener("DOMContentLoaded", function (event) {

    // INIT MENUBUTTON
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    // INIT LIGHTBOX
    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });

    // INIT SWIPER
    const sliders = document.querySelectorAll('.swiper');
    sliders.forEach(function (slider) {

        const count = slider.querySelectorAll('.swiper-slide').length;

        if (count === 1) {
            slider.classList.add('swiper--single');
        } else {
            const autoSwiper = slider.classList.contains('swiper--auto');
            const swiper = new Swiper(slider, {
                // configure Swiper to use modules
                modules: [Pagination, Navigation, Autoplay, EffectFade],
                effect: 'slide',
                fadeEffect: {
                    crossFade: true
                },
                direction: 'horizontal',
                loop: true,

                autoplay: autoSwiper ? {
                    delay: 5000,
                } : false,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            });
        }
    });

    // Show/hide header on scroll if cover is present
    const header = document.querySelector('.header');
    const cover = document.querySelector('.section--cover');

    if (cover) {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 30) {
                header.classList.add('header--visible');
            } else {
                header.classList.remove('header--visible');
            }
        });

        // Activate navigation on scroll
        const nav_items = document.querySelectorAll('.nav_menu_link');

        nav_items.forEach(function (nav_item) {
            nav_item.addEventListener('click', function (event) {
                document.body.classList.remove('body--show');
            });
        });

        const sections = document.querySelectorAll('[data-menu]');
        const sections_list = [];
        sections.forEach(function (section) {
            sections_list.push({
                id: section.getAttribute('id'),
                offset: section.offsetTop,
            });
        });

        window.addEventListener('scroll', function () {
            const windowPosition = window.scrollY;
            const activeSection = sections_list.filter(function (section) {
                return section.offset <= windowPosition + 200;
            }).pop();
            nav_items.forEach(function (nav_item) {
                nav_item.classList.remove('nav_menu_link--active');
            });
            if (activeSection) {
                const menuItem = document.querySelector(`#menu-${activeSection.id}`);
                if (menuItem) {
                    menuItem.classList.add('nav_menu_link--active');
                }
            }
        });

    }

    let tile_toggles = document.querySelectorAll('[data-behaviour="tile_toggledetails"]');

    if (tile_toggles.length > 0) {
        tile_toggles.forEach(function (tile_toggle) {
            let tile = tile_toggle.closest('.tile');
            let tile_image = tile.querySelector('.tile_image');
            let tile_summary = tile.querySelector('.tile_summary');
            let tile_details = tile.querySelector('.tile_details');
            let tile_summary_height = tile_summary.offsetHeight + 15;
            // tile.style.maxHeight = tile_summary_height + 'px';

            const toggle = function (event) {
                event.preventDefault();
                tile = tile_toggle.closest('.tile');
                tile_summary = tile.querySelector('.tile_summary');
                tile_details = tile.querySelector('.tile_details');
                tile_summary_height = tile_summary.offsetHeight + 15;
                const tile_detail_height = tile_details.offsetHeight;
                const totalheight = tile_summary_height + tile_detail_height + 60;
                if (tile.classList.contains('tile--open')) {
                    tile.classList.remove('tile--open');
                    tile_details.classList.add('hidden');
                    tile.style.maxHeight = tile_summary_height + 'px';
                } else {
                    tile.classList.add('tile--open');
                    tile.style.maxHeight = totalheight + 'px';
                    tile.style.height = totalheight + 'px';
                    tile_details.scrollIntoView({behavior: "smooth", block: "start"});
                }

                tile_toggles.forEach(function (other_tile_toggle) {
                    if (tile_toggle !== other_tile_toggle) {
                        const other_tile = other_tile_toggle.closest('.tile');
                        other_tile.classList.remove('tile--open');
                    }
                });
            }

            tile_toggle.addEventListener('click', toggle);
            tile_image.addEventListener('click', toggle);
        });
    }

    const userForms = document.querySelectorAll('form.userform');

    userForms.forEach(function (userForm) {
        userForm.addEventListener('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(userForm);
            const formAction = userForm.getAttribute('action');
            const formMethod = userForm.getAttribute('method');
            const formButton = userForm.querySelector('input[type="submit"]');
            const formMessage = userForm.parentNode.querySelector('.form-message');

            formButton.setAttribute('disabled', 'disabled');

            fetch(formAction, {
                method: formMethod,
                body: formData
            }).then(function (response) {
                // Ignore response
                formMessage.classList.add('form-message--success');
                console.log('XXX', formMessage);
                userForm.reset();
            }).catch(function (error) {
                // Ignore error
            }).finally(function () {
                formButton.removeAttribute('disabled');
            });
        });
    });


});
